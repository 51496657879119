import type {
  AdPosition,
  SsaiAdBreakMetadata,
  SsaiAdMetadata,
  SsaiAdQuartile,
  SsaiAdQuartileMetadata,
} from '../../api/AdapterAPI';
import {CustomDataValues, extractCustomDataFieldsOnly} from '../../types/CustomDataValues';
import {logger} from '../../utils/Logger';
import {isValidString} from '../../utils/stringUtils';
import {isNotNullish, isNullish, isPlainObject, transferCustomDataFields} from '../../utils/Utils';

export function isAdPosition(anything: unknown): anything is AdPosition {
  // invalid, when not defined as one of string values we expect
  return anything === 'preroll' || anything === 'midroll' || anything === 'postroll';
}

/** User SSAI API input validation */
export function hasValidSsaiAdBreakMetadataStructure(
  anything: unknown,
): anything is SsaiAdBreakMetadata | null | undefined {
  //  "nullish" is considered to be valid (SSAI API parameter is optional)
  if (isNullish(anything)) return true;

  // invalid, when value was defined, but not as a plain object
  if (!isPlainObject(anything as object)) {
    logger.errorMessageToUser('Invalid SsaiAdBreakMetadata: not a plain object');
    return false;
  }

  const maybeSsaiAdBreakMetadata = anything as SsaiAdBreakMetadata;

  // invalid, when `adPosition` was defined, but not as an ad position string we expect
  if (isNotNullish(maybeSsaiAdBreakMetadata.adPosition) && !isAdPosition(maybeSsaiAdBreakMetadata.adPosition)) {
    logger.errorMessageToUser("Invalid SsaiAdBreakMetadata: 'adPosition' property is not valid");
    return false;
  }

  return true;
}

/**
 * Simple sanitization of {@link SsaiAdBreakMetadata} without object structure check,
 * because {@link hasValidSsaiAdBreakMetadataStructure} should be called before in adapter SSAI API function!
 *
 * Sanitization:
 *  - if `undefined/null` is passed, it will return `undefined`
 *  - recreate a new object to avoid side effects by using same reference from outside
 *
 * @param adBreakMetadata SsaiAdBreakMetadata or `undefined` (optional SSAI API parameter)
 */
export function simplySanitizeSsaiAdBreakMetadata(
  adBreakMetadata: SsaiAdBreakMetadata | undefined,
): SsaiAdBreakMetadata | undefined {
  //  "nullish" is considered to be valid (SSAI API parameter is optional)
  if (isNullish(adBreakMetadata)) {
    // sanitize to use `undefined` in code
    return undefined;
  }

  // recreate a new object
  return {
    adPosition: adBreakMetadata.adPosition,
  };
}

/** User SSAI API input validation */
export function hasValidSsaiAdMetadataStructure(anything: unknown): anything is SsaiAdMetadata | null | undefined {
  //  "nullish" is considered to be valid (SSAI API parameter is optional)
  if (isNullish(anything)) {
    return true;
  }

  // invalid, when value was defined, but not as a plain object
  if (!isPlainObject(anything as object)) {
    logger.errorMessageToUser('Invalid SsaiAdMetadata: not a plain object');
    return false;
  }

  const maybeSsaiAdMetadata = anything as SsaiAdMetadata;

  // invalid, when `adId` was defined, but not as a string
  if (isNotNullish(maybeSsaiAdMetadata.adId) && !isValidString(maybeSsaiAdMetadata.adId)) {
    logger.errorMessageToUser("Invalid SsaiAdMetadata: 'adId' property is not string");
    return false;
  }

  // invalid, when `adSystem` was defined, but not as a string
  if (isNotNullish(maybeSsaiAdMetadata.adSystem) && !isValidString(maybeSsaiAdMetadata.adSystem)) {
    logger.errorMessageToUser("Invalid SsaiAdMetadata: 'adSystem' property is not string");
    return false;
  }

  // invalid, when `customData` was defined, but not as an object
  if (isNotNullish(maybeSsaiAdMetadata.customData) && !isPlainObject(maybeSsaiAdMetadata.customData)) {
    logger.errorMessageToUser("Invalid SsaiAdMetadata: 'customData' property is not a plain object");
    return false;
  }

  return true;
}

/**
 * Simple sanitization of {@link SsaiAdMetadata} without object structure check,
 * because {@link hasValidSsaiAdMetadataStructure} should be called before in adapter SSAI API function!
 *
 * Sanitization:
 *  - if `undefined/null` is passed, it will return `undefined`
 *  - recreate a new object to avoid side effects by using same reference from outside
 *
 * @param ssaiAdMetadata SsaiAdMetadata or `undefined` (optional SSAI API parameter)
 */
export function simplySanitizeSsaiAdMetadata(ssaiAdMetadata: SsaiAdMetadata | undefined): SsaiAdMetadata | undefined {
  //  "nullish" is considered to be valid (SSAI API parameter is optional)
  if (isNullish(ssaiAdMetadata)) {
    // sanitize to use `undefined` in code
    return undefined;
  }

  let sanitizedCustomDataValues: CustomDataValues | undefined = undefined;

  // we are extracting the customData fields explicitly to make sure that there is not a different object passed
  if (isNotNullish(ssaiAdMetadata.customData)) {
    const extracted = extractCustomDataFieldsOnly(ssaiAdMetadata.customData);
    sanitizedCustomDataValues = {};
    transferCustomDataFields(extracted, sanitizedCustomDataValues);
  }

  // recreate a new object
  return {
    adId: ssaiAdMetadata.adId,
    adSystem: ssaiAdMetadata.adSystem,
    customData: sanitizedCustomDataValues,
  };
}

export function isSsaiAdQuartile(anything: unknown): anything is SsaiAdQuartile {
  return anything === 'first' || anything === 'midpoint' || anything === 'third' || anything === 'completed';
}

/** User SSAI API input validation */
export function hasValidSsaiAdQuartileStructure(anything: unknown): anything is SsaiAdQuartile {
  if (!isSsaiAdQuartile(anything)) {
    logger.errorMessageToUser('Invalid SsaiAdQuartile');
    return false;
  }

  return true;
}

/**
 * Full sanitization of unknown customer input.
 * We sanitize correct object structure and print user error messages for incorrect input.
 *
 * Sanitization:
 *  - if `undefined/null` is passed, it will return empty object `{}`
 *  - if NOT `typeof 'object'` is passed, it will return empty object `{}` and print user error message
 *  - recreate a new object to avoid side effects by using same reference from outside
 *  - if `failedBeaconUrl` is not valid string, we use `undefined` and print user error message
 *  - if `failedBeaconUrl` is longer than 500 characters, we cut it
 *
 *  @param anything Can be anything, as it is coming from customer JavaScript code
 */
export function sanitizeSsaiAdQuartileMetadata(anything: unknown): SsaiAdQuartileMetadata {
  if (isNullish(anything)) {
    //  "nullish" is considered to be valid (SSAI API parameter is optional)
    // sanitize to plain object to not use nullish value in code
    return {};
  }

  if (!isPlainObject(anything as object)) {
    // if NOT a plain object, we return empty plain object and print user error message
    logger.errorMessageToUser('Invalid SsaiAdQuartileMetadata: not a plain object');
    return {};
  }

  const maybeSsaiAdQuartileMetadata = anything as SsaiAdQuartileMetadata;

  // recreate a new object
  const ssaiAdQuartileMetadata: SsaiAdQuartileMetadata = {
    failedBeaconUrl: maybeSsaiAdQuartileMetadata.failedBeaconUrl,
  };

  if (!isValidString(ssaiAdQuartileMetadata.failedBeaconUrl)) {
    logger.errorMessageToUser("Invalid SsaiAdQuartileMetadata: 'failedBeaconUrl' property is not string");
    ssaiAdQuartileMetadata.failedBeaconUrl = undefined;
  } else {
    ssaiAdQuartileMetadata.failedBeaconUrl = ssaiAdQuartileMetadata.failedBeaconUrl.substring(0, 500);
  }

  return ssaiAdQuartileMetadata;
}
