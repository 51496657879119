import {HTML5AnalyticsStateMachine} from '../../analyticsStateMachines/HTML5AnalyticsStateMachine';
import {Analytics} from '../../core/Analytics';
import {HeartbeatService} from '../../core/HeartbeatService';
import {Event} from '../../enums/Event';
import {Player} from '../../enums/Player';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {normalizeVideoDuration, PlaybackInfo} from '../../types/PlaybackInfo';
import {isNotNullish} from '../../utils/Utils';
import {InternalAdapter} from '../internal/InternalAdapter';
import {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

export class CAFv3InternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  private playerManager;
  private mediaInformation;
  private activeAudioTrack;
  private activeTextTracks;
  private currentItem;
  private isSeeking: boolean;

  constructor(
    protected context: any,
    opts?: AnalyticsStateMachineOptions,
  ) {
    super(opts);
    const playingHeartbeatService = new HeartbeatService(() => context.getPlayerManager().getCurrentTimeSec());
    const stateMachine = new HTML5AnalyticsStateMachine(this.stateMachineCallbacks, playingHeartbeatService, this.opts);
    playingHeartbeatService.setListener(stateMachine);
    this.playerManager = context.getPlayerManager();
    this.stateMachine = stateMachine;
    this.isSeeking = false;
  }

  initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    this.register();
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );
    return [errorDetailTracking];
  }

  getPlayerVersion(): string {
    return (window as any).cast && (window as any).cast.framework ? (window as any).cast.framework.VERSION : 'unknown';
  }

  getPlayerName = () => Player.CHROMECAST_SHAKA;
  getPlayerTech = () => 'html5';
  getAutoPlay = () => (this.currentItem && this.currentItem.autoplay ? this.currentItem.autoplay : false);
  getDrmPerformanceInfo = (): DrmPerformanceInfo | undefined => this.drmPerformanceInfo;

  sourceChange(config: AnalyticsConfig) {
    this.stateMachine.callManualSourceChangeEvent(config, this.playerManager.getCurrentTimeSec());
  }

  getCurrentPlaybackInfo(): PlaybackInfo {
    const stats = (this.playerManager as any).getStats();

    const info: PlaybackInfo = {
      isLive: this.getIsLive(),
      playerTech: this.getPlayerTech(),
      videoDuration: normalizeVideoDuration(this.mediaInformation ? this.mediaInformation.duration : 0),
      videoPlaybackHeight: stats.height,
      videoPlaybackWidth: stats.width,
      audioCodec: this.activeAudioTrack ? this.activeAudioTrack.trackContentType : undefined,
      audioLanguage: this.activeAudioTrack ? this.activeAudioTrack.language : undefined,
      subtitleLanguage: this.activeTextTracks ? this.activeTextTracks.map((t) => t.language).join(',') : undefined,
      droppedFrames: 0, // TODO
    };

    return info;
  }

  register() {
    this.playerManager.addEventListener('PLAYER_LOADING', (_event) => {
      this.eventCallback(Event.SETUP, {});
    });

    this.playerManager.addEventListener('PLAYER_LOAD_COMPLETE', (event) => {
      const audioTracksManager = this.playerManager.getAudioTracksManager();
      this.activeAudioTrack = audioTracksManager.getActiveTrack();

      // captions
      const textTracksManager = this.playerManager.getTextTracksManager();
      this.activeTextTracks = textTracksManager.getActiveTracks();

      const queueManager = this.playerManager.getQueueManager();
      this.currentItem = queueManager.getCurrentItem();

      this.mediaInformation = event.media;

      this.eventCallback(Event.READY, {});
    });

    this.playerManager.addEventListener('PLAY', (event) => {
      this.eventCallback(Event.PLAY, {
        currentTime: event.currentMediaTime,
      });
    });

    this.playerManager.addEventListener('PAUSE', (_event) => {
      this.onPaused(this.playerManager.getCurrentTimeSec());
    });

    this.playerManager.addEventListener('ERROR', (event) => {
      const errorData = isNotNullish(event.error) ? event.error : undefined;
      this.eventCallback(Event.ERROR, {
        currentTime: this.playerManager.getCurrentTimeSec(),
        code: event.detailedErrorCode,
        message: event.reason,
        legacyData: errorData,
        data: {additionalData: JSON.stringify(errorData)},
      });
    });

    this.playerManager.addEventListener('SEEKING', (event) => {
      this.isSeeking = true;
      this.onPaused(event.currentMediaTime);
      this.eventCallback(Event.SEEK, {
        currentTime: event.currentMediaTime,
      });
    });

    this.playerManager.addEventListener('SEEKED', (event) => {
      this.isSeeking = false;
      this.eventCallback(Event.SEEKED, {
        currentTime: event.currentMediaTime,
      });
    });

    // Fired when the browser is trying to fetch media data,
    // but did not receive a response. The cast.framework.events.EventType.BUFFERING
    // event is implemented consistently across stream types, and should be used instead
    // of 'stalled' when trying to check if the player is buffering.
    this.playerManager.addEventListener('STALLED', (_event) => undefined);

    this.playerManager.addEventListener('BUFFERING', (event) => {
      if (!this.isSeeking && event.isBuffering) {
        this.eventCallback(Event.START_BUFFERING, {
          currentTime: this.currentTime,
        });
      }
    });

    this.playerManager.addEventListener('TIME_UPDATE', (event) => {
      if (!this.isSeeking) {
        this.eventCallback(Event.TIMECHANGED, {
          currentTime: event.currentMediaTime,
        });
      }
    });

    this.playerManager.addEventListener('REQUEST_LOAD', (_event) => {
      // this event is triggered whenever new content is to be played
      // it's not necessary to do license call and reset all data if this is the first item being played
      if (this.currentItem) {
        this.eventCallback(Event.PLAYLIST_TRANSITION, {
          currentTime: this.playerManager.getCurrentTimeSec(),
        });
      }
    });
  }

  onPaused(currentTime?: number) {
    this.eventCallback(Event.PAUSE, {
      currentTime,
    });
  }

  protected get currentTime(): number {
    return this.playerManager.getCurrentTimeSec();
  }

  private getIsLive(): boolean | undefined {
    // needs to be explicitly set to "LIVE" by customers in their receiver implementation
    if (this.mediaInformation && this.mediaInformation.streamType === 'LIVE') {
      return true;
    }

    // hack because object exists only for live streams
    const liveSeekableRange = this.playerManager.getLiveSeekableRange();

    if (liveSeekableRange) {
      return true;
    }

    return undefined;
  }
}
