import type {MediaPlayer} from 'amazon-ivs-player';

import {AmazonIVSStateMachine} from '../../analyticsStateMachines/amazonivs/AmazonIVSStateMachine';
import {Analytics} from '../../core/Analytics';
import {HeartbeatService} from '../../core/HeartbeatService';
import VideoCompletionTracker from '../../core/VideoCompletionTracker';
import {Player} from '../../enums/Player';
import {PlayerSize} from '../../enums/PlayerSize';
import {StreamTypes} from '../../enums/StreamTypes';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {normalizeVideoDuration, PlaybackInfo} from '../../types/PlaybackInfo';
import * as Utils from '../../utils/Utils';
import HTMLVideoElementStatisticsProvider from '../html5/player/HTMLVideoElementStatisticsProvider';
import {AdModuleAPI} from '../internal/ads/AdModuleAPI';
import {InternalAdapter} from '../internal/InternalAdapter';
import {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {AmazonIVSPlayerContext} from './player/AmazonIVSPlayerContext';
import {AmazonIVSPlayerEventListener} from './player/AmazonIVSPlayerEventListener';
import {SourceUrlProvider} from './player/SourceUrlProvider';
import CollectorEvents from './utils/CollectorEventBus';
import {WindowEventListener} from './window/WindowEventListener';

export class AmazonIVSInternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  private listener: AmazonIVSPlayerEventListener;
  private windowEventListener: WindowEventListener;
  private playerStatisticsProvider: HTMLVideoElementStatisticsProvider;

  constructor(
    private player: MediaPlayer,
    private sourceUrlProvider: SourceUrlProvider,
    opts?: AnalyticsStateMachineOptions,
  ) {
    super(opts);
    const playingHeartbeatService = new HeartbeatService(() => this.player.getPosition());
    const stateMachine = new AmazonIVSStateMachine(this.stateMachineCallbacks, playingHeartbeatService, this.opts);
    playingHeartbeatService.setListener(stateMachine);
    this.stateMachine = stateMachine;

    const playerContext = new AmazonIVSPlayerContext(player);
    this.listener = AmazonIVSPlayerEventListener.create(this, playerContext, this.qualityChangeService);

    this.playerStatisticsProvider = new HTMLVideoElementStatisticsProvider(player.getHTMLVideoElement());

    this.windowEventListener = new WindowEventListener(this.windowEventTracker, this, playerContext);
  }

  supportsDeferredLicenseLoading = false;
  adModule?: AdModuleAPI;
  videoCompletionTracker?: VideoCompletionTracker;

  initialize(_analytics: Analytics): Feature<FeatureConfigContainer, FeatureConfig>[] {
    this.listener.startMonitoring(this.player);
    this.windowEventListener.startMonitoring();
    CollectorEvents.RELEASE.subscribe(() => {
      this.release();
    });
    // we do not support any analytics features on IVS yet
    return [];
  }

  protected override get currentTime(): number {
    return this.player.getPosition();
  }

  override resetSourceRelatedState() {
    this.drmPerformanceInfo = undefined;
    this.playerStatisticsProvider.reset();
  }

  getPlayerVersion = () => this.player.getVersion();
  getPlayerName = () => Player.AMAZON_IVS;
  getPlayerTech = () => 'html5';
  getAutoPlay = () => this.player.isAutoplay();
  getDrmPerformanceInfo = () => undefined;
  getCurrentPlaybackInfo(): PlaybackInfo {
    const videoElement = this.player.getHTMLVideoElement();

    const info: PlaybackInfo = {
      droppedFrames: this.playerStatisticsProvider.getDroppedFrames(),
      isLive: this.player.getDuration() === Infinity,
      isMuted: this.player.isMuted(),
      playerTech: this.getPlayerTech(),
      videoWindowWidth: videoElement.clientWidth,
      videoWindowHeight: videoElement.clientHeight,
      videoPlaybackHeight: videoElement.videoHeight,
      videoPlaybackWidth: videoElement.videoWidth,
      videoBitrate: this.player.getQuality().bitrate,
      videoDuration: normalizeVideoDuration(this.player.getDuration()),
      size: Utils.isVideoInFullscreen() ? PlayerSize.Fullscreen : PlayerSize.Window,
      ...this.getVideoAndAudioCodecs(),
      ...this.getCommonPlaybackInfo(),
      streamFormat: StreamTypes.hls,
      m3u8Url: this.sourceUrlProvider.m3u8Url,
    };
    return info;
  }

  sourceChange(_config: AnalyticsConfig) {
    throw new Error('Method not implemented.');
  }

  private getVideoAndAudioCodecs() {
    const codecs = this.player.getQuality().codecs.split(',');
    if (codecs.length == 2) {
      return {
        videoCodec: codecs[0],
        audioCodec: codecs[1],
      };
    }
    return {};
  }
}
