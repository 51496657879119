import type {SsaiAdBreakMetadata} from '../../api/AdapterAPI';
import {isNotNullish} from '../../utils/Utils';

/**
 * Utility function to sanitize the adPosition value in the SsaiAdBreakMetadata object passed to Bitmovin8Adapter.
 *
 * Needed, because we have introduced SSAI API in `Bitmovin8Adapter` in v2.39.0, without doing proper validation of
 * the passed adPosition value and customer started to use us with invalid values (having `-` in string in JavaScript).
 * Because we did not want to break the customer's implementation, we decided to sanitize the value to the correct one.
 *
 * @deprecated This function will be removed in v3
 */
export const sanitizeBitmovin8AdapterSsaiAdBreakMetadataAdPosition = <T = unknown>(anything: T): T => {
  const maybeSsaiAdBreakMetadata = anything as SsaiAdBreakMetadata;

  if (isNotNullish(maybeSsaiAdBreakMetadata?.adPosition)) {
    if ((maybeSsaiAdBreakMetadata.adPosition as string) === 'pre-roll') {
      maybeSsaiAdBreakMetadata.adPosition = 'preroll';
    } else if ((maybeSsaiAdBreakMetadata.adPosition as string) === 'mid-roll') {
      maybeSsaiAdBreakMetadata.adPosition = 'midroll';
    } else if ((maybeSsaiAdBreakMetadata?.adPosition as string) === 'post-roll') {
      maybeSsaiAdBreakMetadata.adPosition = 'postroll';
    }
  }

  return maybeSsaiAdBreakMetadata as T;
};
