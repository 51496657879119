import {Event} from './Event';

export enum StartupFailureReason {
  PAGE_CLOSED = 'PAGE_CLOSED',
  PLAYER_ERROR = 'PLAYER_ERROR',
  TIMEOUT = 'TIMEOUT',
  UNKNOWN = 'UNKNOWN',
}

export function createStartupFailureReason(stateMachineEvent: string | undefined): StartupFailureReason {
  switch (stateMachineEvent) {
    case Event.ERROR:
      return StartupFailureReason.PLAYER_ERROR;
    case Event.UNLOAD:
      return StartupFailureReason.PAGE_CLOSED;
    case Event.VIDEOSTART_TIMEOUT:
      return StartupFailureReason.TIMEOUT;

    default:
      return StartupFailureReason.UNKNOWN;
  }
}
