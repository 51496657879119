/*!
 * This file was created to avoid "values imports" from 'bitmovin-player@8.202.0' package.
 *
 * We should still use `import type {}` for all Bitmovin Player types.
 */

/* eslint-disable perfectionist/sort-enums */

/**
 * Copy of `HttpRequestType` enum from Bitmovin Player
 *
 * @see https://github.com/bitmovin-engineering/bitdash/blob/v8.202.0/src/core/NetworkAPI.ts#L11
 */
export enum HttpRequestType {
  MANIFEST_DASH = 'manifest/dash',
  MANIFEST_HLS_MASTER = 'manifest/hls/master',
  MANIFEST_HLS_VARIANT = 'manifest/hls/variant',
  MANIFEST_SMOOTH = 'manifest/smooth',
  MANIFEST_ADS = 'manifest/ads',

  MEDIA_AUDIO = 'media/audio',
  MEDIA_VIDEO = 'media/video',
  MEDIA_SUBTITLES = 'media/subtitles',
  MEDIA_THUMBNAILS = 'media/thumbnails',
  MEDIA_SEGMENTINDEX = 'media/segmentindex',

  DRM_LICENSE_WIDEVINE = 'drm/license/widevine',
  DRM_LICENSE_PLAYREADY = 'drm/license/playready',
  DRM_LICENSE_FAIRPLAY = 'drm/license/fairplay',
  DRM_LICENSE_PRIMETIME = 'drm/license/primetime',
  DRM_LICENSE_CLEARKEY = 'drm/license/clearkey',

  DRM_CERTIFICATE_FAIRPLAY = 'drm/certificate/fairplay',

  KEY_HLS_AES = 'key/hls/aes',

  TIME_SYNC = 'time/sync',

  WEBRTC_SDP_REQUEST = 'webrtc/sdp/request',
  WEBRTC_SDP_ANSWER = 'webrtc/sdp/answer',
  WEBRTC_SDP_OFFER = 'webrtc/sdp/offer',

  UNKNOWN = 'unknown',

  INTERNAL = 'internal',
}

/* eslint-enable perfectionist/sort-enums */
