/*!
 * This file was created to avoid imports from 'hls.js@1.6.0' package,
 * when we need to use not only types, but also values (enums/classes/etc.)
 */
import type {Events} from 'hls.js';

/**
 * Partial copy from Hls.js repository
 * @see https://github.com/video-dev/hls.js/blob/v1.6.0/src/types/events.ts#L390
 */
export interface FragLoadedData {
  frag: Fragment;
  networkDetails: unknown;
}

/**
 * Partial copy from Hls.js repository
 * @see https://github.com/video-dev/hls.js/blob/v1.6.0/src/types/loader.ts#L175
 */
export enum PlaylistLevelType {
  AUDIO = 'audio',
  MAIN = 'main',
  SUBTITLE = 'subtitle',
}

/**
 * Partial copy from Hls.js repository
 * @see https://github.com/video-dev/hls.js/blob/v1.6.0/src/loader/fragment.ts#L163
 */
interface Fragment {
  stats: LoaderStats;
  readonly type: PlaylistLevelType;
}

/**
 * Partial copy from Hls.js repository
 * @see https://github.com/video-dev/hls.js/blob/v1.6.0/src/types/loader.ts#L73
 */
interface LoaderStats {
  total: number;
  loading: {
    end: number;
    first: number;
    start: number;
  };
}

/**
 * Map of Hls Player events that we listen to, each should be type of {@link Events}.
 *
 * Why: This way, we don't need to import string enum {@link Events} from 'hls.js' directly,
 * aka. compile/include it (as value) in our distribution code
 */
export const HLS_PLAYER_EVENT = {
  MEDIA_ATTACHING: 'hlsMediaAttaching' as Events.MEDIA_ATTACHING,
  MEDIA_DETACHING: 'hlsMediaDetaching' as Events.MEDIA_DETACHING,
  MANIFEST_LOADING: 'hlsManifestLoading' as Events.MANIFEST_LOADING,
  AUDIO_TRACK_SWITCHED: 'hlsAudioTrackSwitched' as Events.AUDIO_TRACK_SWITCHED,
  SUBTITLE_TRACK_SWITCH: 'hlsSubtitleTrackSwitch' as Events.SUBTITLE_TRACK_SWITCH,
  DESTROYING: 'hlsDestroying' as Events.DESTROYING,
  ERROR: 'hlsError' as Events.ERROR,
} as const;
