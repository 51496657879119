import {ProgressiveSourceConfig} from '../types/ProgressiveSourceConfig';
import {ProgressiveSourceInfo} from '../types/SourceInfo';

import {isNotNullish} from './Utils';

export const getSourceInfoFromBitmovinSourceConfig = (
  progressive: ProgressiveSourceConfig,
  player: any,
): ProgressiveSourceInfo => {
  if (!progressive) {
    return {
      progBitrate: undefined,
      progUrl: undefined,
    };
  }

  if (typeof progressive === 'string') {
    return {
      progBitrate: 0,
      progUrl: progressive,
    };
  }

  if (Array.isArray(progressive) && progressive.length > 0) {
    const playbackVideoData = player.getPlaybackVideoData();
    let progressiveArrayIndex = 0;

    if (isNotNullish(playbackVideoData) && !isNaN(+playbackVideoData.id)) {
      progressiveArrayIndex = +playbackVideoData.id;
    }

    return {
      progBitrate: progressive[progressiveArrayIndex].bitrate || 0,
      progUrl: progressive[progressiveArrayIndex].url,
    };
  }

  if (typeof progressive === 'object' && !Array.isArray(progressive)) {
    return {
      progBitrate: progressive.bitrate || 0,
      progUrl: progressive.url,
    };
  }

  return {
    progBitrate: undefined,
    progUrl: undefined,
  };
};
