import type {DownloadFinishedEvent, PlayerAPI, PlayerExports} from 'bitmovin-player';

import {EventDispatcher, Subscribable} from '../../../core/EventDispatcher';
import {OnDownloadFinishedEventObject} from '../../../features/httprequesttracking/OnDownloadFinishedEventObject';
import {calculateTimeWithUndefined, getCurrentTimestamp} from '../../../utils/Utils';
import {HttpRequestType} from '../bitmovin8PlayerTypes';

export class HttpRequestTrackingAdapter implements Subscribable<OnDownloadFinishedEventObject> {
  private eventDispatcher = new EventDispatcher<OnDownloadFinishedEventObject>();
  private playerExports: PlayerExports;

  constructor(player: PlayerAPI) {
    this.playerExports = player.exports;
    player.on(this.playerExports.PlayerEvent.DownloadFinished, (event) =>
      this.onDownloadFinished(event as DownloadFinishedEvent),
    );
  }

  subscribe(callback: (args: OnDownloadFinishedEventObject) => void): () => void {
    return this.eventDispatcher.subscribe(callback);
  }

  unsubscribe(callback: (args: OnDownloadFinishedEventObject) => void) {
    this.eventDispatcher.unsubscribe(callback);
  }

  private onDownloadFinished(event?: DownloadFinishedEvent) {
    this.eventDispatcher.dispatch({
      httpRequest: {
        downloadTime: calculateTimeWithUndefined(event?.downloadTime) ?? 0,
        httpStatus: event?.httpStatus ?? 0,
        success: event?.success ?? false,
        timestamp: getCurrentTimestamp(),
        url: event?.url,
        size: event?.size,
        timeToFirstByte: calculateTimeWithUndefined(event?.timeToFirstByte),
        type: this.mapHttpRequestType(event?.downloadType),
      },
    });
  }

  private mapHttpRequestType(downloadType?: string): HttpRequestType {
    // Map downloadType to the HttpRequestType values
    if (Object.values(HttpRequestType).includes(downloadType as HttpRequestType)) {
      return downloadType as HttpRequestType;
    }
    return HttpRequestType.UNKNOWN;
  }
}
