import type {VideoJsPlayer} from 'video.js';

import {VideojsAnalyticsStateMachine} from '../../analyticsStateMachines/VideoJsAnalyticsStateMachine';
import {Analytics} from '../../core/Analytics';
import {HeartbeatService} from '../../core/HeartbeatService';
import VideoCompletionTracker from '../../core/VideoCompletionTracker';
import * as CodecHelper from '../../enums/Codecs';
import {Event} from '../../enums/Event';
import {getMIMETypeFromFileExtension} from '../../enums/MIMETypes';
import {Player} from '../../enums/Player';
import {PlayerSize} from '../../enums/PlayerSize';
import {getStreamTypeFromMIMEType} from '../../enums/StreamTypes';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import {Feature} from '../../features/Feature';
import {FeatureConfig} from '../../features/FeatureConfig';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {CodecInfo} from '../../types/CodecInfo';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {VideoChangeEvent} from '../../types/EventData';
import {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import {normalizeVideoDuration, PlaybackInfo} from '../../types/PlaybackInfo';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {StreamSources} from '../../types/StreamSources';
import {isDifferentSubtitleInfo, SubtitleInfo} from '../../types/SubtitleInfo';
import {isBlank, isValidString} from '../../utils/stringUtils';
import {isNotNullish, isNullish} from '../../utils/Utils';
import {InternalAdapter} from '../internal/InternalAdapter';
import {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

// Declaration merging to extend the VideoJsPlayer interface.
// @see https://www.typescriptlang.org/docs/handbook/declaration-merging.html
declare module 'video.js' {
  interface VideoJsPlayer {
    // Extend the VideoJsPlayer interface with missing `version` function, introduced in 8.10.0
    // by https://github.com/videojs/video.js/pull/8543/files, but not present in `@types/video.js@7.3.58` yet.
    //
    // Function set as nullable to be able to covert older VideoJs players
    //
    // see: https://github.com/videojs/video.js/blob/6fe68e5dad6853d8f0eaf45534873607c1b32277/src/js/player.js#L1345
    version?(): {'video.js': string};
  }
}
export class VideojsInternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  readonly videoCompletionTracker: VideoCompletionTracker;

  private readonly player: VideoJsPlayer;

  private _subtitleInfo: SubtitleInfo = {enabled: false};
  private selectedAudioLanguage?: string;
  private onBeforeUnLoadEvent = false;
  private previousTime = 0;

  constructor(player: any, opts?: AnalyticsStateMachineOptions) {
    super(opts);
    this.player = player;
    const playingHeartbeatService = new HeartbeatService(() => this.currentTime);
    const stateMachine = new VideojsAnalyticsStateMachine(
      this.stateMachineCallbacks,
      playingHeartbeatService,
      this.opts,
    );
    playingHeartbeatService.setListener(stateMachine);
    this.stateMachine = stateMachine;

    this.videoCompletionTracker = new VideoCompletionTracker();
  }

  override resetSourceRelatedState() {
    super.resetSourceRelatedState();

    this.videoCompletionTracker.reset();
    this.videoCompletionTracker.setVideoDuration(this.player.duration());
    this.previousTime = 0;
  }

  initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    this.register();
    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );
    return [errorDetailTracking];
  }

  getPlayerVersion = () => {
    // try to get the version from the player instance
    // - `VideoJsPlayer.version()` was introduced in 8.10.0, so we need to check for it
    const playerInstanceVersion = this.player.version?.()['video.js'];
    if (isValidString(playerInstanceVersion) && !isBlank(playerInstanceVersion)) {
      return playerInstanceVersion;
    }

    // try to get the version from the global `window.videojs.VERSION` (if npm module is used, it doesn't exist)
    const windowVideoJsVersion = window.videojs?.['VERSION'];
    if (isValidString(windowVideoJsVersion) && !isBlank(windowVideoJsVersion)) {
      return windowVideoJsVersion;
    }

    return 'unknown';
  };
  getPlayerName = () => Player.VIDEOJS;
  getPlayerTech = () => 'html5';
  getAutoPlay = () => this.player.autoplay() === true;
  getDrmPerformanceInfo = (): DrmPerformanceInfo | undefined => this.drmPerformanceInfo;

  getStreamType(url: string) {
    let mimeType = getMIMETypeFromFileExtension(url);
    if (mimeType) {
      return getStreamTypeFromMIMEType(mimeType);
    }
    mimeType = this.player.currentType?.();
    if (mimeType) {
      return getStreamTypeFromMIMEType(mimeType);
    }

    return undefined;
  }

  // this seems very generic. one could put it in a helper
  // and use it in many adapter implementations.
  getStreamSources(url: string): StreamSources {
    const streamType = this.getStreamType(url);
    switch (streamType) {
      case 'hls':
        return {m3u8Url: url};
      case 'dash':
        return {mpdUrl: url};
      default:
        return {progUrl: url};
    }
  }

  getCurrentPlaybackInfo(): PlaybackInfo {
    this.selectedAudioLanguage = this.getSelectedAudioTrackLanguage(this.player);
    const codecInfo = this.getCodecInfo(this.player.tech({IWillNotUseThisInPlugins: true}));

    const info: PlaybackInfo = {
      ...this.getCommonPlaybackInfo(),
      ...this.getStreamSources(this.player.currentSrc()),
      streamFormat: this.getStreamType(this.player.currentSrc()),
      isLive: isNaN(this.player.duration()) ? undefined : this.player.duration() === Infinity,
      size: this.player.isFullscreen() ? PlayerSize.Fullscreen : PlayerSize.Window,
      playerTech: this.getPlayerTech(),
      isMuted: this.player.muted(),
      videoDuration: normalizeVideoDuration(this.player.duration()),
      videoWindowHeight: this.player.height(),
      videoWindowWidth: this.player.width(),
      videoPlaybackHeight: this.player.videoHeight(),
      videoPlaybackWidth: this.player.videoWidth(),
      audioLanguage: this.selectedAudioLanguage,
      subtitleEnabled: this._subtitleInfo.enabled,
      subtitleLanguage: this._subtitleInfo.language,
      videoCodec: codecInfo?.videoCodec,
      audioCodec: codecInfo?.audioCodec,
      droppedFrames: 0, // TODO
      // TODO audioBitrate:
      // TODO isCasting:
      // TODO videoTitle: (currently only from the analytics config)
    };

    const qualityInfo = this.getCurrentQualityLevelInfo();
    if (qualityInfo) {
      info.videoPlaybackWidth = qualityInfo.width ?? info.videoPlaybackWidth;
      info.videoPlaybackHeight = qualityInfo.height ?? info.videoPlaybackHeight;
      info.videoBitrate = qualityInfo.bitrate ?? info.videoBitrate;
    }

    return info;
  }

  getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    return this.currentVideoJsQualityLevel();
  }

  register() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    // if one source is being played and we load new source and start playing it
    // we need to reset all session related data and initialize new session
    // videojs does not provide a way for figuring out when current source or player is unloaded
    // so we need to reset everything with "manualSourceChange" event every time new source is loaded
    this.player.on('loadstart', function (this: any) {
      that.eventCallback(Event.MANUAL_SOURCE_CHANGE, {config: {}});
    });
    this.player.on('loadedmetadata', function (this: any) {
      that.videoCompletionTracker.reset();
      that.videoCompletionTracker.setVideoDuration(this.duration());
      that.previousTime = 0;
      that.eventCallback(Event.SOURCE_LOADED, {});
    });
    this.player.ready(function (this: any) {
      that._subtitleInfo = that.getSubtitleInfo(this);
      that.eventCallback(Event.READY, {});
    });
    this.player.on('play', function (this: any) {
      that.eventCallback(Event.PLAY, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('pause', function (this: any) {
      if (!this.seeking()) {
        that.eventCallback(Event.PAUSE, {
          currentTime: this.currentTime(),
        });
      }
    });
    this.player.on('error', function (this: any) {
      const error: MediaError = this.error();
      that.eventCallback(Event.ERROR, {
        currentTime: this.currentTime(),
        code: error.code,
        message: error.message,
        data: {},
      });
    });
    this.player.on('volumechange', function (this: any) {
      const muted = this.muted();
      const volume = this.volume();
      const isMuted = that.isAudioMuted(muted, volume);

      if (this.previousMutedValue !== isMuted) {
        if (isMuted) {
          that.eventCallback(Event.MUTE, {
            currentTime: this.currentTime(),
          });
        } else {
          that.eventCallback(Event.UN_MUTE, {
            currentTime: this.currentTime(),
          });
        }
        this.previousMutedValue = isMuted;
      }
    });
    this.player.on('seeking', function (this: any) {
      that.eventCallback(Event.SEEK, {
        currentTime: isNotNullish(that.previousTime) ? that.previousTime : this.currentTime(),
      });
    });
    this.player.on('seeked', function (this: any) {
      that.eventCallback(Event.SEEKED, {
        currentTime: this.currentTime(),
      });
    });

    this.player.on('texttrackchange', function (this: any) {
      const newSubtitleInfo = that.getSubtitleInfo(this);
      const hasInfoChanged = isNullish(that._subtitleInfo)
        ? true
        : isDifferentSubtitleInfo(that._subtitleInfo, newSubtitleInfo);
      that._subtitleInfo = newSubtitleInfo;

      if (hasInfoChanged) {
        that.eventCallback(Event.SUBTITLE_CHANGE, {
          currentTime: this.currentTime(),
        });
      }
    });

    const audioTracks = this.player.audioTracks();
    audioTracks.on('change', () => {
      const newSelectedAudioLanguage = that.getSelectedAudioTrackLanguage(that.player);
      if (isNotNullish(newSelectedAudioLanguage) && newSelectedAudioLanguage !== that.selectedAudioLanguage) {
        that.eventCallback(Event.AUDIOTRACK_CHANGED, {
          currentTime: that.player.currentTime(),
        });
      }
    });

    this.player.on('stalled', function (this: any) {
      that.eventCallback(Event.START_BUFFERING, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('waiting', function (this: any) {
      that.eventCallback(Event.START_BUFFERING, {
        currentTime: this.currentTime(),
      });
    });
    this.player.on('timeupdate', function (this: any) {
      if (!this.seeking()) {
        that.previousTime = this.currentTime();
      }
      that.eventCallback(Event.TIMECHANGED, {
        currentTime: this.currentTime(),
      });

      const qualityInfo = that.currentVideoJsQualityLevel();
      if (qualityInfo?.height && qualityInfo?.width && qualityInfo?.bitrate) {
        if (that.qualityChangeService.shouldAllowVideoQualityChange(qualityInfo.bitrate)) {
          const eventObject: VideoChangeEvent = {
            bitrate: qualityInfo.bitrate,
            height: qualityInfo.height,
            width: qualityInfo.width,
            currentTime: this.currentTime(),
          };
          that.eventCallback(Event.VIDEO_CHANGE, eventObject);
        }
        that.qualityChangeService.setVideoBitrate(qualityInfo.bitrate);
      }
    });

    let handlePageClose = () => {
      if (!this.onBeforeUnLoadEvent) {
        this.onBeforeUnLoadEvent = true;
        let currentTime: number | undefined;
        if (isNotNullish(this.player)) {
          currentTime = this.player.currentTime();
        }
        this.eventCallback(Event.UNLOAD, {
          currentTime,
        });
      }
      this.release();
    };

    handlePageClose = handlePageClose.bind(this);

    this.player.on('dispose', handlePageClose);
    this.windowEventTracker.addEventListener('beforeunload', handlePageClose);
    this.windowEventTracker.addEventListener('unload', handlePageClose);
  }

  sourceChange(config: AnalyticsConfig) {
    this.stateMachine.callManualSourceChangeEvent(config, this.currentTime);
  }

  protected get currentTime(): number {
    return this.player.currentTime();
  }

  private getSelectedAudioTrackLanguage(player: any): string | undefined {
    for (const track of player.audioTracks()) {
      if (track.enabled) {
        return track.language;
      }
    }
    return undefined;
  }

  private getSubtitleInfo(player: any): SubtitleInfo {
    const textTracks = player.textTracks() || [];
    let enabled = false;
    let language;
    for (const track of textTracks) {
      if (track.mode === 'showing') {
        enabled = true;
        language = track.language;
        break;
      }
    }
    return {
      enabled,
      language,
    };
  }

  private currentVideoJsQualityLevel(): QualityLevelInfo | null {
    // Check for HLS source-handler (http-streaming)
    // When we just use Videojs without any specific source-handler (not using MSE API based engine)
    // but just native technology (HTML5/Flash) to do for example "progressive download" with plain Webm/Mp4
    // or use native HLS on Safari this may not be present. In that case Videojs is just
    // a wrapper around the respective playback tech (HTML or Flash).
    const tech = this.player.tech({IWillNotUseThisInPlugins: true}) as any;

    if (isNullish(tech)) {
      return null;
    }

    if (isNullish(tech.vhs) && isNullish(tech.hls)) {
      return null;
    }

    const mediaAttributes = this.getMediaAttributes(tech);

    if (!mediaAttributes || !mediaAttributes.bitrate) {
      return null;
    }

    return {
      bitrate: mediaAttributes.bitrate,
      width: mediaAttributes.width,
      height: mediaAttributes.height,
    };
  }

  // more detailed info from segment can be obtained using
  // https://github.com/videojs/http-streaming#segment-metadata
  // but some properties are not available in older videojs versions
  private getMediaAttributes(playerTech: any): MediaAttributes | undefined {
    if (!playerTech) {
      return;
    }

    // From here we are going onto Videojs-HLS source-handler specific API
    let sourceHandler;
    if (playerTech.vhs) {
      sourceHandler = playerTech.vhs;
    } else if (playerTech.hls) {
      // older videojs versions don't have vhs property
      sourceHandler = playerTech.hls;
    }

    // Maybe we have the HLS source-handler initialized, but it is
    // not actually activated and used (just wrapping HTML5 built-in HLS playback like in Safari)
    if (!sourceHandler || !sourceHandler.playlists || typeof sourceHandler.playlists.media !== 'function') {
      return;
    }

    // Check for current media playlist
    const selectedPlaylist = sourceHandler.playlists.media();

    if (!selectedPlaylist) {
      return;
    }

    const {attributes} = selectedPlaylist;

    if (!attributes || Object.keys(attributes).length === 0) {
      return;
    }

    return {
      bitrate: attributes.BANDWIDTH,
      width: (attributes.RESOLUTION || {}).width,
      height: (attributes.RESOLUTION || {}).height,
      codecs: attributes.CODECS,
    };
  }

  private getCodecInfo(playerTech: any): CodecInfo | undefined {
    const mediaAttributes = this.getMediaAttributes(playerTech);

    if (!mediaAttributes || !mediaAttributes.codecs) {
      return;
    }

    const codecs = mediaAttributes.codecs.split(',');

    if (!codecs) {
      return;
    }

    return {
      videoCodec: codecs.find((codec: string) => CodecHelper.isVideoCodec(codec)),
      audioCodec: codecs.find((codec: string) => CodecHelper.isAudioCodec(codec)),
    };
  }
}

export interface MediaAttributes {
  codecs: string | undefined;
  bitrate: number | undefined;
  width: number | undefined;
  height: number | undefined;
}
