import type {PlayerAPI, PlayerConfig} from 'bitmovin-player';

import type {
  AdapterAPI,
  SsaiAdBreakMetadata,
  SsaiAdMetadata,
  SsaiAdQuartile,
  SsaiAdQuartileMetadata,
  SsaiApi,
} from '../../api/AdapterAPI';
import {Analytics} from '../../core/Analytics';
import {
  hasValidSsaiAdBreakMetadataStructure,
  hasValidSsaiAdMetadataStructure,
  hasValidSsaiAdQuartileStructure,
  sanitizeSsaiAdQuartileMetadata,
  simplySanitizeSsaiAdBreakMetadata,
  simplySanitizeSsaiAdMetadata,
} from '../../core/ssai/ssaiApiUtils';
import type {AnalyticsConfig} from '../../types/AnalyticsConfig';
import type {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import type {CustomDataValues} from '../../types/CustomDataValues';
import {guardAgainstDuplicatedUserId, sanitizeAnalyticsConfig} from '../../utils/analyticsConfigUtils';
import {logger} from '../../utils/Logger';
import {
  hasPlayerAlreadyBeenAugmented,
  isDefined,
  markPlayerInstanceAsAugmented,
} from '../../utils/playerAugmentationUtils';
import {VERSION} from '../../utils/Version';
import type {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {sanitizeBitmovin8AdapterSsaiAdBreakMetadataAdPosition} from './bitmovin8AdapterSsaiApiSanitizeUtils';
import {Bitmovin8InternalAdapter} from './Bitmovin8InternalAdapter';

export class Bitmovin8Adapter implements AdapterAPI {
  private readonly internalAdapter: InternalAdapterAPI | undefined;
  private readonly analytics: Analytics | undefined;

  constructor(player: any, opts?: AnalyticsStateMachineOptions) {
    if (hasPlayerAlreadyBeenAugmented(player)) {
      logger.errorMessageToUser('Bitmovin Analytics is already hooked up to this player instance');
      return;
    }
    markPlayerInstanceAsAugmented(player);

    const playerConfig: PlayerConfig = (player as PlayerAPI).getConfig();
    const sanitizedConfig = sanitizeAnalyticsConfig(playerConfig.analytics as AnalyticsConfig);
    guardAgainstDuplicatedUserId(sanitizedConfig);
    sanitizedConfig.playerKey = sanitizedConfig.playerKey ?? playerConfig.key;

    this.internalAdapter = Bitmovin8InternalAdapter.create(player, opts);
    this.analytics = Analytics.create(sanitizedConfig, this.internalAdapter);

    // We do this here in order to put the reference of the adapter onto the player
    // customer using the bitmovin adapter standalone might not know that they need to
    // hold a reference
    player.analytics = this;

    this.wrapPlayerLoad(player, this.analytics);
  }

  readonly ssai: SsaiApi = {
    adBreakStart: (adBreakMetadata?: SsaiAdBreakMetadata) => {
      if (!isDefined(this.internalAdapter)) return;

      // quick fix for v2.39.0 missing validation of adPosition
      const adBreakMetadataWithValidAdPosition = sanitizeBitmovin8AdapterSsaiAdBreakMetadataAdPosition(adBreakMetadata);

      if (!hasValidSsaiAdBreakMetadataStructure(adBreakMetadataWithValidAdPosition)) {
        // if we don't have valid ad break metadata, we don't want to proceed and use SSAI API
        return;
      }

      const sanitizedAdBreakMetadata = simplySanitizeSsaiAdBreakMetadata(adBreakMetadataWithValidAdPosition);

      this.internalAdapter.ssaiService.adBreakStart(sanitizedAdBreakMetadata);
    },

    adStart: (adMetadata?: SsaiAdMetadata) => {
      if (!isDefined(this.analytics)) return;
      if (!isDefined(this.internalAdapter)) return;

      if (!hasValidSsaiAdMetadataStructure(adMetadata)) {
        // if we don't have valid ad metadata, we don't want to proceed and use SSAI API
        return;
      }

      const sanitizedAdMetadata = simplySanitizeSsaiAdMetadata(adMetadata);

      this.internalAdapter.ssaiService.adStart(sanitizedAdMetadata);
      // we need to call this here to revert the customData values to the values before the adBreak
      // this is mainly needed to have clear transitions between ads, and we don't
      // copy over customData values from the first ad into the second ad
      // the adMetadata is applied after this call in the manipulate method when a sample is sent
      // it is a bit of a hack, but it is necessary right now due to the way how samples are created
      this.analytics.setConfigParameters();
    },

    adQuartileFinished: (adQuartile: SsaiAdQuartile, adQuartileMetadata?: SsaiAdQuartileMetadata) => {
      if (!isDefined(this.analytics)) return;
      if (!isDefined(this.internalAdapter)) return;

      if (!hasValidSsaiAdQuartileStructure(adQuartile)) {
        // if we don't have valid ad quartile, we don't want to proceed and use SSAI API
        return;
      }

      const sanitizedSsaiAdQuartileMetadata = sanitizeSsaiAdQuartileMetadata(adQuartileMetadata);

      this.internalAdapter.ssaiService.adQuartileFinished(adQuartile, sanitizedSsaiAdQuartileMetadata);
    },

    adBreakEnd: () => {
      if (!isDefined(this.analytics)) return;
      if (!isDefined(this.internalAdapter)) return;

      this.internalAdapter.ssaiService.adBreakEnd();
      // we need to call this here to revert the customData values to the values we had before the ad
      this.analytics.setConfigParameters();
    },
  };

  /*
   * intercept `player.load` with automated sourceChange handling
   */
  private wrapPlayerLoad(player: PlayerAPI, analytics: Analytics) {
    const originalLoad = player.load;
    player.load = (...args: Parameters<typeof player.load>): Promise<void> => {
      if (args.length > 0) {
        const analyticsConfig = (args[0] as any).analytics;
        // we reset the analytics and reload with a new config
        analytics.sourceChange(analyticsConfig);
      }

      return originalLoad.apply(player, args);
    };
  }

  static readonly version: string = VERSION;

  get version(): string {
    return VERSION;
  }

  getCurrentImpressionId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getCurrentImpressionId();
  }

  getUserId(): string | undefined {
    if (!isDefined(this.analytics)) return;

    return this.analytics.getUserId();
  }

  setCustomData(values: CustomDataValues) {
    if (!isDefined(this.internalAdapter)) return;

    this.internalAdapter.setCustomData(values);
  }

  setCustomDataOnce(values: CustomDataValues) {
    if (!isDefined(this.analytics)) return;

    this.analytics.setCustomDataOnce(values);
  }

  sourceChange(config: AnalyticsConfig) {
    if (!isDefined(this.analytics)) return;

    this.analytics.sourceChange(config);
  }
}
