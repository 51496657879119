import {StateMachine} from 'javascript-state-machine';

import {AnalyticsStateMachine} from '../../analyticsStateMachines/AnalyticsStateMachine';
import {
  createHeartbeatPayload,
  customStateMachineErrorCallback,
  logMissingCallbackWarning,
  on,
} from '../../analyticsStateMachines/stateMachineUtils';
import type {HeartbeatListener, HeartbeatService} from '../../core/HeartbeatService';
import {Event, EventMap} from '../../enums/Event';
import type {AnalyticsConfig} from '../../types/AnalyticsConfig';
import type {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import type {AnalyticsEventBase} from '../../types/EventData';
import type {NoExtraProperties} from '../../types/NoExtraProperties';
import type {StateMachineCallbacks} from '../../types/StateMachineCallbacks';
import {EventDebugging} from '../../utils/EventDebugging';
import {logger, padRight} from '../../utils/Logger';

export enum State {
  END = 'END',
  ERROR = 'ERROR',
  PAUSE = 'PAUSE',
  PLAYING = 'PLAYING',
  READY = 'READY',
  SETUP = 'SETUP',
  SOURCE_LOADED = 'SOURCE_LOADED',
  STARTUP = 'STARTUP',
  STARTUP_FAILURE = 'STARTUP_FAILURE',
}

export class BitmovinPwxAnalyticsStateMachine extends AnalyticsStateMachine implements HeartbeatListener {
  private enabledDebugging = false;
  private debuggingStates: EventDebugging[] = [];

  private readonly playingHeartbeatService: HeartbeatService;

  constructor(
    stateMachineCallbacks: StateMachineCallbacks,
    playingHeartbeatService: HeartbeatService,
    opts: AnalyticsStateMachineOptions,
  ) {
    super(stateMachineCallbacks, opts);
    this.playingHeartbeatService = playingHeartbeatService;
  }

  getAllStatesBut(states: string[]) {
    return this.getAllStates().filter((i) => states.indexOf(i) < 0);
  }

  getAllStates() {
    return Object.keys(State).map((key) => State[key]);
  }

  override callEvent<StatemachineEvent extends keyof EventMap, EventData extends EventMap[StatemachineEvent]>(
    eventType: StatemachineEvent,
    eventObject: NoExtraProperties<EventMap[StatemachineEvent], EventData>,
    timestamp: number,
  ): void {
    const exec = this.stateMachine[eventType];

    if (exec) {
      try {
        exec.call(this.stateMachine, timestamp, eventObject);
      } catch (e) {
        logger.error('Exception occured in State Machine callback ' + eventType, exec, eventObject, e);
      }
    } else {
      logger.log('Ignored Event: ' + eventType);
    }
  }

  override onSsaiPlaybackInteraction(_timestamp: number, _eventObject: AnalyticsEventBase): void {
    // TODO: [AN-4289] Implement SSAI ad handling for PWX
  }

  override createStateMachine(opts: AnalyticsStateMachineOptions): StateMachine {
    return StateMachine.create({
      initial: State.SETUP,
      error: customStateMachineErrorCallback,
      events: [
        {
          name: Event.SOURCE_LOADED,
          from: [State.SETUP, State.ERROR],
          to: State.READY,
        },
        on(Event.READY).stayIn(State.READY),
        on(Event.PAUSE).stayIn(State.READY),
        on(Event.TIMECHANGED).stayIn(State.READY),
        {name: Event.PLAY, from: State.READY, to: State.STARTUP},
        {name: Event.PLAYING, from: State.READY, to: State.PLAYING},

        on(Event.PLAY).stayIn(State.STARTUP),
        on(Event.PLAYING).stayIn(State.STARTUP),
        {name: Event.TIMECHANGED, from: State.STARTUP, to: State.PLAYING},
        on(Event.READY).stayIn(State.STARTUP),
        {name: Event.PAUSE, from: State.STARTUP, to: State.READY},

        on(Event.PLAYING).stayIn(State.PLAYING),
        on(Event.TIMECHANGED).stayIn(State.PLAYING),

        {name: Event.PAUSE, from: State.PLAYING, to: State.PAUSE},
        on(Event.TIMECHANGED).stayIn(State.PAUSE),
        on(Event.PAUSE).stayIn(State.PAUSE),
        {name: Event.PLAY, from: State.PAUSE, to: State.PLAYING},
        {name: Event.PLAYING, from: State.PAUSE, to: State.PLAYING},

        {name: Event.END, from: State.PLAYING, to: State.END},
        {name: Event.END, from: State.PAUSE, to: State.END},
        // when replay is triggered (play button is clicked once video finished),
        // we should start a new session, so we should go to STARTUP state
        {name: Event.PLAYING, from: State.END, to: State.STARTUP},

        on(Event.TIMECHANGED).stayIn(State.END),
        on(Event.END).stayIn(State.END),

        {name: Event.PLAY, from: State.END, to: State.STARTUP},
        {name: Event.ERROR, from: State.STARTUP, to: State.STARTUP_FAILURE},
        {name: Event.ERROR, from: this.getAllStatesBut([State.STARTUP]), to: State.ERROR},

        {name: Event.UNLOAD, from: this.getAllStatesBut([State.STARTUP]), to: State.END},
        {name: Event.UNLOAD, from: [State.STARTUP], to: State.STARTUP_FAILURE},
      ],
      callbacks: {
        [`onenter${State.PLAYING}`]: () => {
          this.playingHeartbeatService.startHeartbeat();
        },
        onenterstate: (event, from, to, timestamp, _eventObject) => {
          if (from === 'none' && opts.starttime) {
            this.onEnterStateTimestamp = opts.starttime;
          } else {
            this.onEnterStateTimestamp = timestamp || new Date().getTime();
          }

          logger.log(
            `[ENTER ${timestamp}] ${padRight(to, 20)} EVENT: ${padRight(event, 20)} from: ${padRight(from, 14)}`,
          );
        },
        [`onleave${State.PLAYING}`]: () => {
          this.playingHeartbeatService.stopHeartbeat();
        },
        onleavestate: (event, from, to, timestamp, eventObject) => {
          if (!timestamp) {
            return;
          }

          logger.log(
            `[LEAVE ${timestamp}] ${padRight(from, 20)} EVENT: ${padRight(event, 20)} to: ${padRight(to, 20)}`,
          );

          this.addStatesToLog(event, from, to, timestamp, eventObject);
          const stateDuration = timestamp - this.onEnterStateTimestamp;
          const fromStateName = String(from).toLowerCase();
          const isStartup = from === State.STARTUP && to === State.PLAYING;
          this.maybeSetOrClearVideoStartTimeouts(from, to);

          if (isStartup) {
            this.stateMachineCallbacks.startup(stateDuration, State.STARTUP.toLowerCase());
          }

          if (event === Event.UNLOAD) {
            this.stateMachineCallbacks.unload(stateDuration, fromStateName);
          } else if (from === State.SETUP) {
            // Setting Player Startup Time to fixed value of 1ms.
            // We can not get correct Player Startup Time from PWX player, because it does not have an "onInitialized" event
            this.stateMachineCallbacks.setup(1, State.SETUP.toLowerCase());
          } else if (event === Event.PAUSE && from === State.STARTUP && to === State.READY) {
            // fired PAUSE event in STARTUP state indicates we are moving back to READY,
            // so we have to clean setTimeout, and we should not call any stateMachineCallbacks
            this.clearVideoStartTimeout();
          } else if (from !== State.ERROR && !isStartup) {
            this.handleRestOfTheCallbacks(fromStateName, stateDuration, eventObject, from);
          }
        },
        onplayerError: (_event, _from, _to, _timestamp, eventObject) => {
          this.stateMachineCallbacks.error(eventObject);
        },
      },
    });
  }

  sourceChange(_config: AnalyticsConfig, _timestamp: number, _currentTime?: number): void {
    throw new Error('Method not implemented.');
  }

  onHeartbeat(eventObject: AnalyticsEventBase): void {
    const timestamp = Date.now();
    this.sendCurrentStateSample(timestamp, eventObject);
  }

  addStatesToLog(
    event: string | undefined,
    from: string | undefined,
    to: string | undefined,
    timestamp: number,
    eventObject: any,
  ) {
    if (this.enabledDebugging) {
      this.debuggingStates.push(new EventDebugging(event, from, to, timestamp, eventObject));
    }
  }

  getStates() {
    return this.debuggingStates;
  }

  setEnabledDebugging(enabled: boolean) {
    this.enabledDebugging = enabled;
  }

  private handleRestOfTheCallbacks(
    fromStateName: string,
    stateDuration: number,
    eventObject: any,
    from: string | undefined,
  ) {
    const callbackFunction = this.stateMachineCallbacks[fromStateName];
    if (typeof callbackFunction === 'function') {
      try {
        callbackFunction(stateDuration, fromStateName, eventObject);
      } catch (e) {
        logger.error('Exception occurred in State Machine callback ' + fromStateName, eventObject, e);
      }
    } else {
      logMissingCallbackWarning(from, [State.READY]);
    }
  }

  private maybeSetOrClearVideoStartTimeouts(from: string | undefined, to: string | undefined) {
    if (from === State.READY && to === State.STARTUP) {
      this.setVideoStartTimeout();
    } else if (from === State.STARTUP && to === State.PLAYING) {
      this.clearVideoStartTimeout();
    }
  }

  /**
   * calls the heartbeat callback to send out a sample
   * uses timestamp and eventObject.currentTime to reset time measurements
   * @param timestamp current timestamp
   * @param eventObject contains player information
   * @param triggeredBySsai boolean to mark the sample as SSAI triggered
   */
  private sendCurrentStateSample(timestamp: number, eventObject: AnalyticsEventBase, triggeredBySsai = false) {
    this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);

    const stateDuration = timestamp - this.onEnterStateTimestamp;
    const state = this.stateMachine.current.toLowerCase();
    const payload = createHeartbeatPayload(stateDuration, state as Lowercase<string>, triggeredBySsai);
    this.stateMachineCallbacks.heartbeat(stateDuration, state, payload);

    this.onEnterStateTimestamp = timestamp;
    this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
  }
}
