import type {MediaPlayerClass} from 'dashjs';

import type {CodecInfo} from '../../types/CodecInfo';
import type {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {Converter} from '../../utils/Converter';
import {isBlank, isValidString} from '../../utils/stringUtils';
import {isNullish, isNumber} from '../../utils/Utils';

//#region dashjs API v4.7.4

/**
 * @see https://github.com/Dash-Industry-Forum/dash.js/blob/v4.7.4/index.d.ts#L950
 */
type MediaTypeApiV4 = 'audio' | 'image' | 'text' | 'video';

/**
 * @see https://github.com/Dash-Industry-Forum/dash.js/blob/v4.7.4/index.d.ts#L4110
 */
type BitrateInfoApiV4 = {
  bitrate: number;
  height: number;
  mediaType: MediaTypeApiV4;
  qualityIndex: number;
  scanType: string;
  width: number;
};

type HasApiV4ToGetQualityLevelInfo = {
  getBitrateInfoListFor: (type: MediaTypeApiV4) => BitrateInfoApiV4[];
  getQualityFor: (type: string) => number;
};

function hasApiV4ToGetQualityLevelInfo(mediaPlayer: any): mediaPlayer is HasApiV4ToGetQualityLevelInfo {
  return (
    typeof mediaPlayer['getBitrateInfoListFor'] === 'function' && typeof mediaPlayer['getQualityFor'] === 'function'
  );
}

//#endregion

/**
 * Use dashjs v5 API to get {@link QualityLevelInfo}.
 *
 * Support also dashjs v4 API.
 *
 * @throws Error
 */
export const getQualityLevelInfo = (mediaPlayer: MediaPlayerClass): QualityLevelInfo | null => {
  if (hasApiV4ToGetQualityLevelInfo(mediaPlayer)) {
    const videoBitrateInfoList = mediaPlayer.getBitrateInfoListFor('video');
    const currentVideoQualityIndex = mediaPlayer.getQualityFor('video');
    const currentVideoQuality = videoBitrateInfoList[currentVideoQualityIndex];

    if (isNullish(currentVideoQuality)) {
      return null;
    }

    return {
      width: currentVideoQuality.width,
      height: currentVideoQuality.height,
      bitrate: currentVideoQuality.bitrate,
    };
  }

  const currentVideoRepresentation = mediaPlayer.getCurrentRepresentationForType('video');

  if (isNullish(currentVideoRepresentation)) {
    return null;
  }

  return {
    width: currentVideoRepresentation.width,
    height: currentVideoRepresentation.height,
    bitrate: isNumber(currentVideoRepresentation.bitrateInKbit)
      ? Converter.kiloBitsToBits(currentVideoRepresentation.bitrateInKbit)
      : 0,
  };
};

/**
 * Use dashjs v5 API to get {@link CodecInfo}.
 *
 * Compatible also with dashjs v4 API.
 */
export const getCodecInfo = (mediaPlayer: MediaPlayerClass): CodecInfo => {
  /**
   * Same RegExp for video and audio codec property parsing, because it's string value has same format with `codecs`
   *
   * Parse string and extract codec as first captured group, value between `\"` and `\"`
   *
   * Example 'video' value from player API:
   *    `video/mp4;codecs="avc1.4d400d"`
   * should be parsed by RegExp and return match
   *    `avc1.4d400d`
   *
   * Example 'audio' value from player API:
   *    `audio/mp4;codecs="mp4a.40.5"`
   * should be parsed by RegExp and return match
   *    `mp4a.40.5`
   */
  const codecsRegExp = new RegExp(/codecs="(.*)"/);

  // try-catches in code, because `mediaPlayer.getCurrentTrackFor`
  // can throw STREAMING_NOT_INITIALIZED_ERROR, if called before initializePlayback function
  //
  // see https://cdn.dashjs.org/latest/jsdoc/module-MediaPlayer.html#getCurrentTrackFor

  let videoCodec: string | undefined;
  try {
    const rawVideoCodec = mediaPlayer.getCurrentTrackFor('video')?.codec;
    if (isValidString(rawVideoCodec) && !isBlank(rawVideoCodec)) {
      const matches = codecsRegExp.exec(rawVideoCodec);
      videoCodec = matches?.[1];
    }
  } catch (_e) {
    videoCodec = undefined;
  }

  let audioCodec: string | undefined;
  try {
    const rawAudioCodec = mediaPlayer.getCurrentTrackFor('audio')?.codec;
    if (isValidString(rawAudioCodec) && !isBlank(rawAudioCodec)) {
      const matches = codecsRegExp.exec(rawAudioCodec);
      audioCodec = matches?.[1];
    }
  } catch (_e) {
    audioCodec = undefined;
  }

  return {videoCodec, audioCodec};
};
