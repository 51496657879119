import {isNullish, toBoolean} from '../utils/Utils';

export function sanitizeSessionMetadataAudioLanguage(anything: unknown): string | undefined {
  //  "nullish" is considered to be valid (to "reset" session metadata property)
  if (isNullish(anything)) {
    // sanitize to use `undefined` in code
    return undefined;
  }

  return String(anything).substring(0, 50);
}

export function sanitizeSessionMetadataSubtitleEnabled(anything: unknown): boolean | undefined {
  //  "nullish" is considered to be valid (to "reset" session metadata property)
  if (isNullish(anything)) {
    // sanitize to use `undefined` in code
    return undefined;
  }

  return toBoolean(anything);
}

export function sanitizeSessionMetadataSubtitleLanguage(anything: unknown): string | undefined {
  //  "nullish" is considered to be valid (to "reset" session metadata property)
  if (isNullish(anything)) {
    // sanitize to use `undefined` in code
    return undefined;
  }

  return String(anything).substring(0, 100);
}
