import {Player} from '../../enums/Player';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {PlaybackInfo} from '../../types/PlaybackInfo';
import {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {HTML5InternalAdapter} from './HTML5InternalAdapter';

export class HTMLVideoElementInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  sessionData: InternalHTML5SessionData = {};

  constructor(
    mediaElement: HTMLVideoElement,
    opts?: AnalyticsStateMachineOptions,
    sourceMetadata?: {
      audioCodec?: string;
      audioLanguage?: string;
      streamFormat?: string;
      videoCodec?: string;
    },
  ) {
    super(mediaElement, opts);

    // source metadata
    this.sessionData.streamFormat = sourceMetadata?.streamFormat;
    this.sessionData.audioCodec = sourceMetadata?.audioCodec;
    this.sessionData.videoCodec = sourceMetadata?.videoCodec;
    this.sessionData.audioLanguage = sourceMetadata?.audioLanguage;
  }

  override getPlayerName = () => Player.HTML5;

  getPlayerVersion = () => {
    // player version is set by ingress for html5 player, thus we only return empty string here
    return '';
  };

  override getStreamURL() {
    return this.mediaElement ? this.mediaElement.currentSrc : undefined;
  }

  getCurrentQualityLevelInfo(): QualityLevelInfo | null {
    return {
      bitrate: undefined,
      width: this.mediaElement ? this.mediaElement.videoWidth : undefined,
      height: this.mediaElement ? this.mediaElement.videoHeight : undefined,
    };
  }

  override getCurrentPlaybackInfo(): PlaybackInfo {
    const info = super.getCurrentPlaybackInfo();

    // source metadata
    info.streamFormat = this.sessionData.streamFormat ?? info.streamFormat;
    info.audioCodec = this.sessionData.audioCodec ?? info.audioCodec;
    info.videoCodec = this.sessionData.videoCodec ?? info.videoCodec;

    // language info
    info.subtitleEnabled = this.sessionData.subtitleEnabled ?? info.subtitleEnabled;
    // only set if enabled
    const subtitleLanguage = this.sessionData.subtitleLanguage ?? info.subtitleLanguage;
    info.subtitleLanguage = info.subtitleEnabled === true ? subtitleLanguage : undefined;

    info.audioLanguage = this.sessionData.audioLanguage ?? info.audioLanguage;

    return info;
  }

  override resetSourceRelatedState() {
    super.resetSourceRelatedState();
    this.sessionData = {};
  }
}

/** Represents the internal html5-only adapter state, holding the source and session metadata. */
interface InternalHTML5SessionData {
  streamFormat?: string;
  audioCodec?: string;
  videoCodec?: string;
  audioLanguage?: string;
  subtitleEnabled?: boolean;
  subtitleLanguage?: string;
}
