import type {InternalAdapterAPI} from '../adapters/internal/InternalAdapterAPI';
import type {Analytics} from '../core/Analytics';

import {logger} from './Logger';
import {isNullish} from './Utils';

export const BITMOVIN_ANALYTICS_AUGMENTED_MARKER = '__bitmovinAnalyticsHasBeenSetup';

/**
 * Checks if analytics has already been initialized on this player instance,
 * aka. if {@link BITMOVIN_ANALYTICS_AUGMENTED_MARKER} is present on the player instance.
 *
 * @param player
 */
export function hasPlayerAlreadyBeenAugmented(player: any): boolean {
  return player[BITMOVIN_ANALYTICS_AUGMENTED_MARKER] === true;
}

/**
 * Marks the player instance as already instrumented by analytics,
 * aka. sets {@link BITMOVIN_ANALYTICS_AUGMENTED_MARKER} on the player instance.
 */
export function markPlayerInstanceAsAugmented(player: any) {
  player[BITMOVIN_ANALYTICS_AUGMENTED_MARKER] = true;
}

/**
 * In context of player augmentation, if player was already augmented, adapter properties
 * {@link Analytics} or {@link InternalAdapterAPI} will stay `undefined`, this type guard
 * is utility to help us check if the instance is defined.
 */
export function isDefined<T extends Analytics | InternalAdapterAPI>(instance: T | null | undefined): instance is T {
  if (isNullish(instance)) {
    logger.log("Bitmovin Analytics: Adapter hasn't been initialized yet");
    return false;
  }

  return true;
}
