import {isNotNullish, isNullish} from '../utils/Utils';

export interface SubtitleInfo {
  /**
   * Indicates if subtitles are displayed.
   */
  enabled: boolean;
  /**
   * The selected subtitle/captions language.
   */
  language?: string;
}

export const isDifferentSubtitleInfo = (info1?: SubtitleInfo, info2?: SubtitleInfo): boolean => {
  if (isNullish(info1) && isNullish(info2)) {
    return false;
  }
  return !(
    isNotNullish(info1) &&
    isNotNullish(info2) &&
    info1.enabled === info2.enabled &&
    info1.language === info2.language
  );
};
