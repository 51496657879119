import * as StateMachine from 'javascript-state-machine';

import type {HeartbeatListener, HeartbeatService} from '../core/HeartbeatService';
import {Event, EventMap} from '../enums/Event';
import {createStartupFailureReason} from '../enums/StartupFailureReason';
import type {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import type {AnalyticsEventBase} from '../types/EventData';
import type {NoExtraProperties} from '../types/NoExtraProperties';
import type {StateMachineCallbacks} from '../types/StateMachineCallbacks';
import {logger, padRight} from '../utils/Logger';

import {AnalyticsStateMachine} from './AnalyticsStateMachine';
import {
  createHeartbeatPayload,
  customStateMachineErrorCallback,
  logMissingCallbackWarning,
  on,
} from './stateMachineUtils';

enum State {
  AD = 'AD',
  AUDIOTRACK_CHANGING = 'AUDIOTRACK_CHANGING',
  CASTING = 'CASTING',
  CUSTOMDATACHANGE = 'CUSTOMDATACHANGE',
  END = 'END',
  ERROR = 'ERROR',
  MUTING_PAUSE = 'MUTING_PAUSE',
  MUTING_PLAY = 'MUTING_PLAY',
  MUTING_READY = 'MUTING_READY',
  PAUSE = 'PAUSE',
  PAUSED_SEEKING = 'PAUSED_SEEKING',
  PLAYING = 'PLAYING',
  QUALITYCHANGE = 'QUALITYCHANGE',
  QUALITYCHANGE_PAUSE = 'QUALITYCHANGE_PAUSE',
  QUALITYCHANGE_REBUFFERING = 'QUALITYCHANGE_REBUFFERING',
  READY = 'READY',
  REBUFFERING = 'REBUFFERING',
  SETUP = 'SETUP',
  SETUP_SOURCE_CHANGE = 'SETUP_SOURCE_CHANGE',
  SOURCE_CHANGING = 'SOURCE_CHANGING',
  STARTUP = 'STARTUP',
  STARTUP_FAILURE = 'STARTUP_FAILURE',
  SUBTITLE_CHANGING = 'SUBTITLE_CHANGING',
}

export class HTML5AnalyticsStateMachine extends AnalyticsStateMachine implements HeartbeatListener {
  private readonly playingHeartbeatService: HeartbeatService;

  constructor(
    stateMachineCallbacks: StateMachineCallbacks,
    playingHeartbeatService,
    opts: AnalyticsStateMachineOptions,
  ) {
    super(stateMachineCallbacks, opts);

    this.createStateMachine(opts);
    this.playingHeartbeatService = playingHeartbeatService;
  }

  getAllStates() {
    return [
      ...Object.keys(State).map((key) => State[key]),
      'FINISH_QUALITYCHANGE_PAUSE',
      'FINISH_QUALITYCHANGE',
      'FINISH_QUALITYCHANGE_REBUFFERING',
    ];
  }

  getAllStatesBut(states: string[]) {
    return this.getAllStates().filter((i) => states.indexOf(i) < 0);
  }

  override createStateMachine(opts: AnalyticsStateMachineOptions) {
    return StateMachine.create({
      initial: State.SETUP,
      error: customStateMachineErrorCallback,
      events: [
        {
          name: Event.SOURCE_LOADED,
          from: [State.SETUP, State.SETUP_SOURCE_CHANGE, State.SOURCE_CHANGING],
          to: State.READY,
        },

        on(Event.TIMECHANGED).stayIn(State.SETUP),
        on(Event.SOURCE_LOADED).stayIn(State.READY),

        {name: Event.PLAY, from: State.READY, to: State.STARTUP},
        // play attempt during sourceChanging
        {name: Event.PLAY, from: State.SOURCE_CHANGING, to: State.STARTUP},

        {name: Event.ERROR, from: State.STARTUP, to: State.STARTUP_FAILURE},
        {name: Event.UNLOAD, from: State.STARTUP, to: State.STARTUP_FAILURE},
        {name: Event.VIDEOSTART_TIMEOUT, from: State.STARTUP, to: State.STARTUP_FAILURE},

        // when we have autoplay we will receive souceLoaded after we enter STARTUP state
        on(Event.SOURCE_LOADED).stayIn(State.STARTUP),
        on(Event.START_BUFFERING).stayIn(State.STARTUP),
        on(Event.END_BUFFERING).stayIn(State.STARTUP),
        on(Event.VIDEO_CHANGE).stayIn(State.STARTUP),
        on(Event.AUDIO_CHANGE).stayIn(State.STARTUP),

        {name: Event.TIMECHANGED, from: State.READY, to: State.STARTUP},
        {name: Event.TIMECHANGED, from: State.STARTUP, to: State.PLAYING},
        on(Event.TIMECHANGED).stayIn(State.PLAYING),

        on(Event.SEEKED).stayIn(State.PAUSE),

        on(Event.END_BUFFERING).stayIn(State.PLAYING),
        {name: Event.START_BUFFERING, from: State.PLAYING, to: State.REBUFFERING},
        on(Event.START_BUFFERING).stayIn(State.REBUFFERING),

        {name: Event.PLAY, from: State.REBUFFERING, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.REBUFFERING, to: State.PLAYING},

        // Ignoring since it's pushed in a live stream
        on(Event.SEEK).stayIn(State.STARTUP),
        on(Event.PLAY).stayIn(State.PAUSED_SEEKING),

        {name: Event.PAUSE, from: State.PLAYING, to: State.PAUSE},
        {name: Event.PAUSE, from: State.REBUFFERING, to: State.PAUSE},

        {name: Event.PLAY, from: State.PAUSE, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.PAUSE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        {name: Event.AUDIO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        on(Event.VIDEO_CHANGE).stayIn(State.QUALITYCHANGE),
        on(Event.AUDIO_CHANGE).stayIn(State.QUALITYCHANGE),
        {name: 'FINISH_QUALITYCHANGE', from: State.QUALITYCHANGE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},
        {name: Event.AUDIO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},

        on(Event.VIDEO_CHANGE).stayIn(State.QUALITYCHANGE_PAUSE),
        on(Event.AUDIO_CHANGE).stayIn(State.QUALITYCHANGE_PAUSE),
        {name: 'FINISH_QUALITYCHANGE_PAUSE', from: State.QUALITYCHANGE_PAUSE, to: State.PAUSE},

        {name: Event.SEEK, from: State.PAUSE, to: State.PAUSED_SEEKING},
        on(Event.SEEK).stayIn(State.PAUSED_SEEKING),
        on(Event.AUDIO_CHANGE).stayIn(State.PAUSED_SEEKING),
        on(Event.VIDEO_CHANGE).stayIn(State.PAUSED_SEEKING),
        on(Event.START_BUFFERING).stayIn(State.PAUSED_SEEKING),
        on(Event.END_BUFFERING).stayIn(State.PAUSED_SEEKING),
        {name: Event.SEEKED, from: State.PAUSED_SEEKING, to: State.PAUSE},
        {name: Event.TIMECHANGED, from: State.PAUSED_SEEKING, to: State.PLAYING},
        {name: Event.PAUSE, from: State.PAUSED_SEEKING, to: State.PAUSE},

        {name: Event.END, from: State.PAUSED_SEEKING, to: State.END},
        {name: Event.END, from: State.PLAYING, to: State.END},
        {name: Event.END, from: State.PAUSE, to: State.END},
        on(Event.PAUSE).stayIn(State.END),
        on(Event.SEEK).stayIn(State.END),
        on(Event.SEEKED).stayIn(State.END),
        on(Event.TIMECHANGED).stayIn(State.END),
        on(Event.END_BUFFERING).stayIn(State.END),
        on(Event.START_BUFFERING).stayIn(State.END),
        on(Event.END).stayIn(State.END),

        // Ignored - Livestreams do a Seek during startup and SEEKED once playback started
        on(Event.SEEKED).stayIn(State.PLAYING),
        on(Event.SEEK).stayIn(State.PLAYING),

        {name: Event.PLAY, from: State.END, to: State.PLAYING},

        {name: Event.ERROR, from: this.getAllStatesBut([State.STARTUP]), to: State.ERROR},
        {name: Event.PAUSE, from: State.ERROR, to: State.ERROR},

        {name: Event.UNLOAD, from: this.getAllStatesBut([State.STARTUP]), to: State.END},

        {name: Event.SUBTITLE_CHANGE, from: State.PLAYING, to: State.SUBTITLE_CHANGING},

        on(Event.SUBTITLE_CHANGE).stayIn(State.PAUSE),
        on(Event.SUBTITLE_CHANGE).stayIn(State.READY),
        on(Event.SUBTITLE_CHANGE).stayIn(State.STARTUP),
        on(Event.SUBTITLE_CHANGE).stayIn(State.REBUFFERING),
        on(Event.SUBTITLE_CHANGE).stayIn(State.SUBTITLE_CHANGING),

        {name: Event.TIMECHANGED, from: State.SUBTITLE_CHANGING, to: State.PLAYING},

        {name: Event.AUDIOTRACK_CHANGED, from: State.PLAYING, to: State.AUDIOTRACK_CHANGING},

        on(Event.AUDIOTRACK_CHANGED).stayIn(State.PAUSE),
        on(Event.AUDIOTRACK_CHANGED).stayIn(State.READY),
        on(Event.AUDIOTRACK_CHANGED).stayIn(State.STARTUP),
        on(Event.AUDIOTRACK_CHANGED).stayIn(State.REBUFFERING),
        on(Event.AUDIOTRACK_CHANGED).stayIn(State.AUDIOTRACK_CHANGING),
        {name: Event.TIMECHANGED, from: State.AUDIOTRACK_CHANGING, to: State.PLAYING},

        {name: Event.START_AD, from: State.PLAYING, to: State.AD},
        {name: Event.END_AD, from: State.AD, to: State.PLAYING},

        {name: Event.MUTE, from: State.READY, to: State.MUTING_READY},
        {name: Event.UN_MUTE, from: State.READY, to: State.MUTING_READY},
        {name: 'FINISH_MUTING', from: State.MUTING_READY, to: State.READY},

        {name: Event.MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: Event.UN_MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: 'FINISH_MUTING', from: State.MUTING_PLAY, to: State.PLAYING},

        {name: Event.MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: Event.UN_MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: 'FINISH_MUTING', from: State.MUTING_PAUSE, to: State.PAUSE},

        {name: Event.START_CAST, from: [State.READY, State.PAUSE], to: State.CASTING},
        on(Event.PAUSE).stayIn(State.CASTING),
        on(Event.PLAY).stayIn(State.CASTING),
        on(Event.TIMECHANGED).stayIn(State.CASTING),
        on(Event.MUTE).stayIn(State.CASTING),
        on(Event.SEEK).stayIn(State.CASTING),
        on(Event.SEEKED).stayIn(State.CASTING),
        {name: Event.END_CAST, from: State.CASTING, to: State.READY},

        on(Event.SEEK).stayIn(State.READY),
        on(Event.SEEKED).stayIn(State.READY),
        on(Event.SEEKED).stayIn(State.STARTUP),

        {name: Event.MANUAL_SOURCE_CHANGE, from: State.SETUP, to: State.SETUP_SOURCE_CHANGE},
        on(Event.MANUAL_SOURCE_CHANGE).stayIn(State.SETUP_SOURCE_CHANGE),
        {
          name: Event.MANUAL_SOURCE_CHANGE,
          from: this.getAllStatesBut([State.SETUP, State.SETUP_SOURCE_CHANGE]),
          to: State.SOURCE_CHANGING,
        },
        {name: Event.SOURCE_UNLOADED, from: this.getAllStates(), to: State.SOURCE_CHANGING},

        on(Event.TIMECHANGED).stayIn(State.SOURCE_CHANGING),
        on(Event.PAUSE).stayIn(State.SOURCE_CHANGING),

        {name: Event.VIDEO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        {name: Event.AUDIO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        on(Event.VIDEO_CHANGE).stayIn(State.QUALITYCHANGE_REBUFFERING),
        on(Event.AUDIO_CHANGE).stayIn(State.QUALITYCHANGE_REBUFFERING),
        {name: 'FINISH_QUALITYCHANGE_REBUFFERING', from: State.QUALITYCHANGE_REBUFFERING, to: State.REBUFFERING},

        {name: Event.CUSTOM_DATA_CHANGE, from: [State.PLAYING, State.PAUSE], to: State.CUSTOMDATACHANGE},
        {name: Event.PLAYING, from: State.CUSTOMDATACHANGE, to: State.PLAYING},
        {name: Event.PAUSE, from: State.CUSTOMDATACHANGE, to: State.PAUSE},

        {name: Event.PLAYLIST_TRANSITION, from: this.getAllStates(), to: State.READY},
      ],
      callbacks: {
        [`onenter${State.SOURCE_CHANGING}`]: (event, _from, _to, _timestamp, eventObject) => {
          if (event === Event.MANUAL_SOURCE_CHANGE) {
            this.stateMachineCallbacks.manualSourceChange(eventObject);
          }
        },
        [`onenter${State.STARTUP}`]: (_event, _from, _to, _timestamp, _eventObject) => {
          this.setVideoStartTimeout();
        },
        [`onenter${State.PLAYING}`]: (event, from, to, timestamp) => {
          this.onEnterStateTimestamp = timestamp;
          this.playingHeartbeatService.startHeartbeat();
        },

        onenterstate: (event, from, to, timestamp, eventObject) => {
          if (from === 'none' && opts.starttime) {
            this.onEnterStateTimestamp = opts.starttime;
          } else {
            this.onEnterStateTimestamp = timestamp || Date.now();
          }

          logger.log(
            `[ENTER ${timestamp}] ${padRight(to, 20)} EVENT: ${padRight(event, 20)} from: ${padRight(from, 14)}`,
          );

          if (eventObject && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.START_CAST && to === State.CASTING) {
            this.stateMachineCallbacks.startCasting(timestamp, eventObject);
          }

          if (to === State.REBUFFERING) {
            this.startRebufferingHeartbeatInterval();
          }
        },

        onafterevent: (event, from, to, timestamp, eventObject) => {
          if (event === Event.PLAYLIST_TRANSITION) {
            this.stateMachineCallbacks.playlistTransition(eventObject);
          }
          if (to === State.QUALITYCHANGE_PAUSE) {
            this.stateMachine.FINISH_QUALITYCHANGE_PAUSE(timestamp);
          }
          if (to === State.QUALITYCHANGE) {
            this.stateMachine.FINISH_QUALITYCHANGE(timestamp);
          }
          if (to === State.QUALITYCHANGE_REBUFFERING) {
            this.stateMachine.FINISH_QUALITYCHANGE_REBUFFERING(timestamp);
          }
          if (to === State.MUTING_READY || to === State.MUTING_PLAY || to === State.MUTING_PAUSE) {
            this.stateMachine.FINISH_MUTING(timestamp);
          }
        },
        [`onleave${State.STARTUP}`]: (_event, _from, _to, _timestamp, _eventObject) => {
          this.clearVideoStartTimeout();
        },
        [`onleave${State.PLAYING}`]: (_event, _from, _to, _timestamp, _eventObject) => {
          this.playingHeartbeatService.stopHeartbeat();
        },
        onleavestate: (event, from, to, timestamp, eventObject) => {
          if (from === State.REBUFFERING) {
            this.resetRebufferingHelpers();
          }

          if (!timestamp) {
            return;
          }

          // player errors will be sent via eventCallback() -> callEvent() -> onPlayerError() function chain
          // if then sourceChange is called same error will be sent second time
          if (from === State.ERROR && (event === Event.MANUAL_SOURCE_CHANGE || event === Event.SOURCE_UNLOADED)) {
            return;
          }

          logger.log(
            `[LEAVE ${timestamp}] ${padRight(from, 20)} EVENT: ${padRight(event, 20)} to: ${padRight(to, 20)}`,
          );

          const stateDuration = timestamp - this.onEnterStateTimestamp;

          if (eventObject && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);
          }

          const fnName = String(from).toLowerCase();
          if (to === State.STARTUP_FAILURE) {
            this.clearVideoStartTimeout();
            this.stateMachineCallbacks.videoStartupFailed(createStartupFailureReason(event));
          } else if (from === State.PAUSED_SEEKING) {
            this.stateMachineCallbacks[fnName](stateDuration, fnName, eventObject);
          } else if (event === Event.UNLOAD) {
            this.stateMachineCallbacks.unload(stateDuration, fnName);
          } else if (from === State.PAUSE && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(event);
            this.stateMachineCallbacks.pause(stateDuration, fnName);
          } else {
            const callbackFunction = this.stateMachineCallbacks[fnName];
            if (typeof callbackFunction === 'function') {
              callbackFunction(stateDuration, fnName, eventObject);
            } else {
              logMissingCallbackWarning(from, [State.READY, State.SOURCE_CHANGING, State.SETUP_SOURCE_CHANGE]);
            }
          }

          if (eventObject && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.VIDEO_CHANGE) {
            this.stateMachineCallbacks.videoChange(eventObject);
          } else if (event === Event.AUDIO_CHANGE) {
            this.stateMachineCallbacks.audioChange(eventObject);
          } else if (event === Event.MUTE) {
            this.stateMachineCallbacks.mute();
          } else if (event === Event.UN_MUTE) {
            this.stateMachineCallbacks.unMute();
          }
        },

        onplayerError: (event, from, to, timestamp, eventObject) => {
          this.stateMachineCallbacks.error(eventObject);
        },
      },
    });
  }

  override callEvent<StatemachineEvent extends keyof EventMap, EventData extends EventMap[StatemachineEvent]>(
    eventType: StatemachineEvent,
    eventObject: NoExtraProperties<EventMap[StatemachineEvent], EventData>,
    timestamp: number,
  ): void {
    const exec = this.stateMachine[eventType];

    if (exec) {
      exec.call(this.stateMachine, timestamp, eventObject);
    } else {
      logger.log('Ignored Event: ' + eventType);
    }
  }

  override onSsaiPlaybackInteraction(_timestamp: number, _eventObject: AnalyticsEventBase): void {
    // TODO: [AN-4076] Implement SSAI ad handling for html players
    //  See: https://bitmovin.atlassian.net/browse/AN-4076
  }

  onHeartbeat(eventObject: AnalyticsEventBase): void {
    const timestamp = Date.now();
    this.sendCurrentStateSample(timestamp, eventObject);
  }

  /**
   * calls the heartbeat callback to send out a sample
   * uses timestamp and eventObject.currentTime to reset time measurements
   * @param timestamp current timestamp
   * @param eventObject contains player information
   */
  private sendCurrentStateSample(timestamp: number, eventObject: AnalyticsEventBase) {
    this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);
    const stateDuration = timestamp - this.onEnterStateTimestamp;
    const state = this.stateMachine.current.toLowerCase();

    const payload = createHeartbeatPayload(stateDuration, state as Lowercase<string>);
    this.stateMachineCallbacks.heartbeat(stateDuration, state, payload);
    this.onEnterStateTimestamp = timestamp;

    this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
  }
}
