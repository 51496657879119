import {isNotNullish, isNullish} from './Utils';

export const isValidString = (s: unknown): s is string => isNotNullish(s) && typeof s === 'string';

export const isEmpty = (s: string | null | undefined): boolean => isNullish(s) || s.length === 0;

export const isBlank = (s: string | null | undefined): boolean => isEmpty(s) || isEmpty(s?.trim());

export const endsWith = (s: string, suffix: string): boolean => {
  if (!isValidString(s) || !isValidString(suffix)) {
    return false;
  }

  // our target is ES5, so we can't use String.prototype.endsWith without checking for it
  if (isNotNullish(String.prototype.endsWith)) {
    return s.endsWith(suffix);
  }

  return s.substring(s.length - suffix.length) === suffix;
};

export const startsWith = (s: string, prefix: string): boolean => {
  if (!isValidString(s) || !isValidString(prefix)) {
    return false;
  }

  // our target is ES5, so we can't use String.prototype.startsWith without checking for it
  if (isNotNullish(String.prototype.startsWith)) {
    return s.startsWith(prefix);
  }
  return s.substring(0, prefix.length) === prefix;
};
